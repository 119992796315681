import { Routes, Route } from "react-router-dom";
import VertexCalculatorPage from "./pages/VertexCalculatorPage";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Layout from "./components/layout/Layout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} />
      <Route index element={<VertexCalculatorPage />} />
    </Routes>
  );
}

export default App;
