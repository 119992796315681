const {defaultFraction} = require('./constant'); 
const {convertSpheroCylinder} = require('./convert');
const {calculateSections}= require('../functions/diopter')
const {roundDiopter}= require('./round')
const {checkAxis,checkPositive} = require('./check')

/**
 * Formats the provided value regarding fraction.
 * If fraction <= 100 then round value to 2 digits. Otherwise use the value as is.
 * If result is an integer then return value as integer (i.e. without decimal zero).
 */
 function formatValue(value, fraction = defaultFraction) {
  let formattedValue = value.toString();
  if (fraction <= 100) {
    formattedValue = value.toFixed(2);
  }
  // Remove trailing decimal zero if it's an integer
  if (formattedValue.endsWith('.00')) {
    formattedValue = formattedValue.slice(0, -3); // Remove '.00'
  }
  return formattedValue;
}

/**
 * Formats the base value.
 * If fraction <= 100 then round value to 0 digits and add '°'.
 */
 function formatBase(base, fraction = defaultFraction) {
  checkPositive(base); 
  let formattedBase = base.toString();
  if (fraction <= 100) {
    formattedBase = base.toFixed(0) + '°';
  }
  return formattedBase;
}

/**
 * Formats the sphero-cylinder parameters.
 */
function formatSpheroCylinder({ sphere = 0, cylinder = 0, axis = 0, fraction = defaultFraction, sign = '' }) {
  const convert = convertSpheroCylinder({ sphere, cylinder, axis, sign });
  
  const sections = calculateSections({ sphere: convert.sphere, cylinder: convert.cylinder });
  const newCylinder = sections.section2 - sections.section1;
  
  // Round sections
  const roundSphere = roundDiopter({ diopter: sections.section1, fraction }); 
  const roundCylinder = roundDiopter({ diopter: newCylinder, fraction }); 
  
  // Find total spherocylindrical deviance for each of all possible rounding cases
  const case0 = Math.abs(roundSphere.deviancePositive) + Math.abs(roundCylinder.deviancePositive);
  const case1 = Math.abs(roundSphere.deviancePositive) + Math.abs(roundCylinder.devianceNegative);
  const case2 = Math.abs(roundSphere.devianceNegative) + Math.abs(roundCylinder.deviancePositive);
  const case3 = Math.abs(roundSphere.devianceNegative) + Math.abs(roundCylinder.devianceNegative);
  
  // Find case with the lowest spherocylindrical deviance
  const allCases = [case0, case1, case2, case3];
  allCases.sort((a, b) => a - b);
  const minCase = allCases[0];
  
  // Map cases with corresponding spherocylindrical combination
  sphere = (minCase === case0 || minCase === case1) ? roundSphere.valuePositive : roundSphere.valueNegative;
  cylinder = (minCase === case0 || minCase === case2) ? roundCylinder.valuePositive : roundCylinder.valueNegative;
  axis = checkAxis(convert.axis);
  
  const sectionsNew = calculateSections({ sphere, cylinder });
  const section1 = sectionsNew.section1;
  const section2 = sectionsNew.section2;
  
  // Format the values
  let sphereFormatted = formatValue(sphere, fraction);
  let cylinderFormatted = formatValue(cylinder, fraction);
  let axisFormatted = formatValue(axis, fraction);
  let section1Formatted = formatValue(section1, fraction);
  let section2Formatted = formatValue(section2, fraction);
  
  if (fraction <= 100) {
    axisFormatted = axis.toFixed(0) + '°';
  }
  
  // Remove trailing decimal zero if it's an integer
  if (sphereFormatted.endsWith('.00')) sphereFormatted = sphereFormatted.slice(0, -3);
  if (cylinderFormatted.endsWith('.00')) cylinderFormatted = cylinderFormatted.slice(0, -3);
  if (section1Formatted.endsWith('.00')) section1Formatted = section1Formatted.slice(0, -3);
  if (section2Formatted.endsWith('.00')) section2Formatted = section2Formatted.slice(0, -3);
  
  // Add '+' sign if the value is positive and does not start with '-'
  if (sphereFormatted[0] !== '-') sphereFormatted = '+' + sphereFormatted;
  if (cylinderFormatted[0] !== '-') cylinderFormatted = '+' + cylinderFormatted;
  if (section1Formatted[0] !== '-') section1Formatted = '+' + section1Formatted;
  if (section2Formatted[0] !== '-') section2Formatted = '+' + section2Formatted;
  
  // Handle special case for cylinder
  if (cylinderFormatted === '+0.00') {
    cylinderFormatted = '';
    axisFormatted = '';
  }
  
  return {
    sphere: sphereFormatted,
    cylinder: cylinderFormatted,
    axis: axisFormatted,
    section1: section1Formatted,
    section2: section2Formatted
  };
}

module.exports ={
  formatValue,
  formatBase,
  formatSpheroCylinder
}