import React from "react";
import { RadioButton } from "primereact/radiobutton";

const FractionRadioButtons = ({ selectedFraction, setSelectedFraction }) => {
  const handleChange = (e) => {
    setSelectedFraction(e.value);
  };

  return (
    <div className="flex gap-4 align-items-center">
      <div>
        <RadioButton
          inputId="fraction0_01"
          name="fraction"
          value="±0.01"
          checked={selectedFraction === "±0.01"}
          onChange={handleChange}
        />
        <label htmlFor="fraction0_01">±0.01</label>
      </div>
      <div>
        <RadioButton
          inputId="fraction0_12"
          name="fraction"
          value="±0.12"
          checked={selectedFraction === "±0.12"}
          onChange={handleChange}
        />
        <label htmlFor="fraction0_12">±0.12</label>
      </div>
      <div>
        <RadioButton
          inputId="fraction0_25"
          name="fraction"
          value="±0.25"
          checked={selectedFraction === "±0.25"}
          onChange={handleChange}
        />
        <label htmlFor="fraction0_25">±0.25</label>
      </div>
    </div>
  );
};

export default FractionRadioButtons;
