const { checkAxis,checkPositive } = require("../helpers/check") ;
const { calculateSections,calculateSpheroCylinder } = require("./diopter") ;
const { formatSpheroCylinder } = require("../helpers/format") ;
const {defaultFraction} = require('../helpers/constant'); 


// Calculate vertex for a single section
 function calculateVertexSingle({ section, vertexOld, vertexNew }) {
    vertexOld = checkPositive(vertexOld);
    vertexNew = checkPositive(vertexNew);

    const vertexDistance = vertexOld - vertexNew;
    const result = (section !== 0) ? (1 / (1 / section - vertexDistance / 1000)) : 0;

    return result;
}

// Calculate vertex
 function calculateVertex({
    sphere = 0, cylinder = 0, axis = 0, vertexOld = 0, vertexNew = 0,
    fraction = defaultFraction, sign = ''
}) {
    axis = checkAxis(axis);
    vertexOld = checkPositive(vertexOld);
    vertexNew = checkPositive(vertexNew);

    const calculation = calculateSections({ sphere, cylinder });

    // Calculate new diopter values for each principal section
    const section1 = calculateVertexSingle({ section: calculation.section1, vertexOld, vertexNew });
    const section2 = calculateVertexSingle({ section: calculation.section2, vertexOld, vertexNew });

    // Calculate new spherocylindrical combination
    const result = calculateSpheroCylinder({ section1, section2 });
    const format = formatSpheroCylinder({ sphere: result.sphere, cylinder: result.cylinder, axis, fraction, sign });

    return {
        sphere: result.sphere,
        cylinder: result.cylinder,
        axis: axis,
        formattedSphere: format.sphere,
        formattedCylinder: format.cylinder,
        formattedAxis: format.axis
    };
}

module.exports={
    calculateVertexSingle,
    calculateVertex
}