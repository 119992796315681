const { deg2rad } = require("../helpers/convert");
const { checkAxis } = require("../helpers/check");

// Function to calculate sections based on sphere, cylinder, and axis
function calculateSections({ sphere = 0, cylinder = 0, axis = 0 }) {
    let section1 = sphere;
    let section2 = sphere + cylinder;
    let axis2 = axis + 90;
  
    let axis1 = checkAxis(axis); 
    axis2 = checkAxis(axis2);
  
    return {
      section1,
      axis1,
      section2,
      axis2
    };
  }
  
  // Function to calculate sphere, cylinder, and axis from sections
 function calculateSpheroCylinder({ section1 = 0, section2 = 0, axis1 = 0, axis2 = 0 }) {
    let sphere = section1;
    let cylinder = section2 - section1;
  
    axis1 = checkAxis(axis1); 
    axis2 = checkAxis(axis2); 
  
    return {
      sphere,
      cylinder,
      axis: axis1
    };
  }
  
  // Function to calculate oblique axis
 function calculateObliqueAxis({ sphere = 0, cylinder, axis, axisNew }) {
    let axisOblique = (axisNew - axis < 0) ? axisNew - axis + 180 : axisNew - axis;
    let cylinderNew = cylinder * Math.sin(deg2rad(axisOblique)) ** 2;
  
    axisNew = checkAxis(axisNew);
  
    return {
      sphere,
      cylinder: cylinderNew,
      axis: axisNew
    };
  }
  
  module.exports ={
    calculateObliqueAxis,
    calculateSpheroCylinder,
    calculateSections
  }