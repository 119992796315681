import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ResultTable = ({ values }) => {
  return (
    <div className="flex flex-column gap-4 border-solid border-round-lg px-4 py-2 bg-white">
      <DataTable value={values}>
        <Column field="eye" style={{ border: "none", fontWeight: 700 }} />
        <Column
          field="sphere"
          header="Sphere"
          headerStyle={{ fontWeight: "bold" }}
          style={{ border: "none" }}
        />
        <Column
          field="cylinder"
          header="Cylinder"
          headerStyle={{ fontWeight: "bold" }}
          style={{ border: "none" }}
        />
        <Column
          field="axis"
          header="Axis"
          headerStyle={{ fontWeight: "bold" }}
          style={{ border: "none" }}
        />
      </DataTable>
    </div>
  );
};

export default ResultTable;
