// const { calculateVertex } = require("../package/functions/vertex");

export const roundNumberFromString = (str) => {
    const numericValue = parseFloat(str.replace(/^[+-]/, ""));
    const roundedValue = Math.round(numericValue * 100) / 100;
    const sign = str[0] === "-" ? "-" : "+";
    return sign + roundedValue.toFixed(2);
  };

 export  const parseFormData = (data) => {
    const fields = ["sphere", "cylinder", "axis", "vertexOld", "vertexNew"];
    const calculationResult = {};

    ["OD", "OS"].forEach((eye) => {
      calculationResult[eye] = {};
      fields.forEach((field) => {
        calculationResult[eye][field] = parseFloat(data[`${field}${eye}`]) || 0;
      });
    });

    return calculationResult;
  };

  export   const formatValue = (value) => {
    if (value === undefined || value === null) return "+0.00";
    const num = parseFloat(value);
    if (isNaN(num)) return "+0.00";
    const formatted = num >= 0 ? `+${num.toFixed(2)}` : num.toFixed(2);
    return formatted;
  };

  