// Function to check if a value is positive
 function checkPositive(value) {
    if (value < 0) {
      throw new Error('Value is less than 0.');
    }
    return value;
  }
  
  // Function to check if a value is negative
  function checkNegative(value) {
    if (value > 0) {
      throw new Error('Value is more than 0.');
    }
    return value;
  }
  
  // Function to normalize an axis value to be within the range [0, 180)
 function checkAxis(axis) {
    while (axis >= 180) {
      axis -= 180;
    }
    while (axis < 0) {
      axis += 180;
    }
    return axis;
  }
  
  // Function to check and set values for two axes
function check2Axis({ axisR1 = null, axisR2 = null }) {
    if (axisR1 === null && axisR2 === null) {
      throw new Error('Axes for both meridians are not set.');
    }
  
    if (axisR2 === null) {
      axisR2 = axisR1 + 90;
    }
    if (axisR1 === null) {
      axisR1 = axisR2 + 90;
    }
  
    axisR1 = checkAxis(axisR1);
    axisR2 = checkAxis(axisR2);
  
    if (Math.abs(axisR1 - axisR2) !== 90) {
      throw new Error('Axis for both meridians do not match.');
    }
  
    return { axisR1, axisR2 };
  }
  
  // Function to check and optionally swap two radius values
 function check2Radius(r1, r2) {
    if (r1 < r2) {
      return swapDoubles(r1, r2);
    } else {
      return { r1, r2 };
    }
  }
  
  // Helper function to swap two double values
 function swapDoubles(double1, double2) {
    return { double1: double2, double2: double1 };
  }
  
  module.exports = {
    checkPositive,
    checkNegative,
    checkAxis,
    check2Axis,
    check2Radius,
    swapDoubles
  };