const {defaultFraction} = require('./constant')
const {calculateSections} = require('../functions/diopter')
const {checkAxis} = require('./check')

// Function to round sphero-cylinder parameters
function roundSpheroCylinder({ sphere = 0, cylinder = 0, axis = 0, fraction = defaultFraction }) {
    const sections = calculateSections({ sphere, cylinder }); 
    const newCylinder = sections.section2 - sections.section1;
  
    const roundSphere = roundDiopter({ diopter: sections.section1, fraction });
    const roundCylinder = roundDiopter({ diopter: newCylinder, fraction });
  
    // Find total spherocylindrical deviance for each of all possible rounding cases
    const case0 = Math.abs(roundSphere.deviancePositive) + Math.abs(roundCylinder.deviancePositive);
    const case1 = Math.abs(roundSphere.deviancePositive) + Math.abs(roundCylinder.devianceNegative);
    const case2 = Math.abs(roundSphere.devianceNegative) + Math.abs(roundCylinder.deviancePositive);
    const case3 = Math.abs(roundSphere.devianceNegative) + Math.abs(roundCylinder.devianceNegative);
  
    // Find case with the lowest spherocylindrical deviance
    const allCases = [case0, case1, case2, case3];
    allCases.sort((a, b) => a - b);
    const minCase = allCases[0];
  
    // Map cases with corresponding spherocylindrical combination
    sphere = (minCase === case0 || minCase === case1) ? roundSphere.valuePositive : roundSphere.valueNegative;
    cylinder = (minCase === case0 || minCase === case2) ? roundCylinder.valuePositive : roundCylinder.valueNegative;
    axis = checkAxis(axis);
  
    return {
      sphere,
      cylinder,
      axis
    };
  }
  
  // Function to round a diopter value
  function roundDiopter({ diopter, fraction = defaultFraction }) {
    const multiplicator = diopter * fraction;
    const valuePositive = Math.ceil(multiplicator) / fraction;
    const valueNegative = Math.floor(multiplicator) / fraction;
    const deviancePositive = valuePositive - diopter;
    const devianceNegative = valueNegative - diopter;
    const deviance = Math.abs(deviancePositive) <= Math.abs(devianceNegative) ? deviancePositive : devianceNegative;
    const rounding = Math.abs(devianceNegative) < Math.abs(deviancePositive) ? valueNegative : valuePositive;
  
    return {
      rounding,
      deviance,
      valuePositive,
      valueNegative,
      deviancePositive,
      devianceNegative
    };
  }
  
  module.exports = {
    roundSpheroCylinder,
    roundDiopter
  }