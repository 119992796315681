import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import InputsContainer from "./InputsContainer";
import StyledInput from "./StyledInput";
import CardContainer from "./CardContainer";
import FractionRadioButtons from "./FractionRadioButtons";
import ResultTable from "./ResultTable";
import { yupResolver } from "@hookform/resolvers/yup";
import { parseFormData } from "../../helpers/vertexHelpers";
import { schema } from "../../helpers/vertexSchema";
import {
  powerInputs,
  vertexInputs,
  formatFields,
} from "../../constants/vertexConstants";
import { formatValue } from "../../helpers/vertexHelpers";
import { roundNumberFromString } from "../../helpers/vertexHelpers";
const { calculateVertex } = require("../../package/functions/vertex");

const VertexCalculator = () => {
  const [isCalculated, setIsCalculated] = useState(false);
  const [result, setResult] = useState([]);
  const [fraction, setFraction] = useState(100000000000000);
  const [selectedFraction, setSelectedFraction] = useState("±0.01");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleInputFormat = (data) => {
    formatFields.forEach((key) => {
      let value = data[key];

      if (value !== undefined && value !== null) {
        value = value.trim();
        const formattedValue = value === "" ? "+0.00" : formatValue(value);
        setValue(key, formattedValue);
      } else {
        setValue(key, "+0.00");
      }
    });
  };

  const handleCalculations = (data) => {
    const { OD, OS } = parseFormData(data);
    const resultOD = calculateVertex({
      sphere: OD.sphere,
      cylinder: OD.cylinder,
      axis: OD.axis,
      vertexOld: OD.vertexOld,
      vertexNew: OD.vertexNew,
      fraction,
    });
    const resultOS = calculateVertex({
      sphere: OS.sphere,
      cylinder: OS.cylinder,
      axis: OS.axis,
      vertexOld: OS.vertexOld,
      vertexNew: OS.vertexNew,
      fraction,
    });
    setResult([
      {
        eye: "OD",
        sphere: roundNumberFromString(resultOD.formattedSphere),
        cylinder: roundNumberFromString(resultOD.formattedCylinder),
        axis:
          fraction !== 100000000000000
            ? resultOD.formattedAxis
            : `${resultOD.formattedAxis}°`,
      },
      {
        eye: "OS",
        sphere: roundNumberFromString(resultOS.formattedSphere),
        cylinder: roundNumberFromString(resultOS.formattedCylinder),
        axis:
          fraction !== 100000000000000
            ? resultOS.formattedAxis
            : `${resultOS.formattedAxis}°`,
      },
    ]);
  };

  useEffect(() => {
    if (!isCalculated) return;

    setValue("axisOD", result.find((r) => r.eye === "OD").axis);
    setValue("axisOS", result.find((r) => r.eye === "OS").axis);

    const formData = getValues();
    handleInputFormat(formData);
  }, [isCalculated, getValues, setValue]);

  useEffect(() => {
    if (selectedFraction === "±0.01") setFraction(100000000000000);
    if (selectedFraction === "±0.12") setFraction(8);
    if (selectedFraction === "±0.25") setFraction(4);
  }, [selectedFraction]);

  useEffect(() => {
    if (!isCalculated) return;

    const formData = getValues();
    handleCalculations(formData);
  }, [fraction]);

  const onSubmit = (data) => {
    if (data.axisOD.includes("°")) {
      setValue("axisOD", data.axisOD);
    } else {
      setValue("axisOD", data.axisOD ? `${data.axisOD}°` : "0°");
    }

    handleInputFormat(data);
    handleCalculations(data);
    setIsCalculated(true);
  };

  const renderInputs = (inputs, eye) =>
    inputs.map((input, index) => {
      const inputName = `${input.name}${eye}`;
      return (
        <div key={index} className="relative">
          <StyledInput
            register={register}
            name={`${input.name}${eye}`}
            placeholder={input.placeholder}
          />
          <p style={{ fontSize: "10px" }} className="absolute text-red-500">
            {errors[inputName]?.message}
          </p>
        </div>
      );
    });

  return (
    <div className="flex flex-column gap-4 w-max ">
      <FractionRadioButtons
        selectedFraction={selectedFraction}
        setSelectedFraction={setSelectedFraction}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-column align-items-start justify-content-center gap-4"
      >
        <div className="flex align-items-center justify-content-center w-full gap-4">
          <CardContainer>
            <h3>Lens power</h3>
            <InputsContainer>
              <p className="font-bold">OD</p>
              {renderInputs(powerInputs, "OD")}
            </InputsContainer>
            <InputsContainer>
              <p className="font-bold">OS</p>
              {renderInputs(powerInputs, "OS")}
            </InputsContainer>
          </CardContainer>
          <CardContainer>
            <h3> Vertex</h3>
            <InputsContainer>
              <p className="font-bold">OD</p>
              {renderInputs(vertexInputs, "OD")}
            </InputsContainer>
            <InputsContainer>
              <p className="font-bold">OS</p>
              {renderInputs(vertexInputs, "OS")}
            </InputsContainer>
          </CardContainer>
        </div>
        <div className="flex gap-4">
          <Button className="p-button-secondary bg-gray-800">Calculate</Button>
          <Button
            type="button"
            className="text-gray-800 bg-white "
            outlined
            onClick={() => reset()}
          >
            Clear
          </Button>
        </div>
      </form>
      {isCalculated && <ResultTable values={result}></ResultTable>}
    </div>
  );
};

export default VertexCalculator;
