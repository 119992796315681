export const powerInputs = [
    { name: "sphere", placeholder: "Sphere" },
    { name: "cylinder", placeholder: "Cylinder" },
    { name: "axis", placeholder: "Axis" },
  ];
  
  export const vertexInputs = [
    { name: "vertexOld", placeholder: "Vertex old" },
    { name: "vertexNew", placeholder: "Vertex new" },
  ];

  export const formatFields = ["sphereOD", "cylinderOD", "sphereOS", "cylinderOS"];
