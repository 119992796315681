import React from "react";
import VertexCalculator from "../components/vertexCalculator/VertexCalculator";

const VertexCalculatorPage = () => {
  return (
    <div className="flex-grow-1 p-8  surface-300 h-screen ">
      <VertexCalculator />
    </div>
  );
};

export default VertexCalculatorPage;
