import * as yup from "yup";

const numberPattern =
  /^([+-]?(?:[0-7]?\d(?:\.\d*)?|80(?:\.0*)?)?)$|^([+-]?(?:8[0]|[0-7]\d)(?:\.\d*)?)$/;
const axisPattern = /^\d*°?$/;
const vertexPattern = /^([0-2]?\d(\.\d+)?|30(\.0+)?)?$/;

export const schema = yup.object({
    sphereOD: yup.string().matches(numberPattern, "Invalid number format"),
    sphereOS: yup.string().matches(numberPattern, "Invalid number format"),
    cylinderOD: yup.string().matches(numberPattern, "Invalid number format"),
    cylinderOS: yup.string().matches(numberPattern, "Invalid number format"),
    axisOD: yup.string().matches(axisPattern, "Invalid number format"),
    axisOS: yup.string().matches(axisPattern, "Invalid number format"),
    vertexOldOD: yup.string().matches(vertexPattern, "Invalid number format"),
    vertexOldOS: yup.string().matches(vertexPattern, "Invalid number format"),
    vertexNewOD: yup.string().matches(vertexPattern, "Invalid number format"),
    vertexNewOS: yup.string().matches(vertexPattern, "Invalid number format"),
  });