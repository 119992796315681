const{pi}= require('./constant')
const{checkAxis}= require('./check')

// Function to convert degrees to radians
function deg2rad(degree) {
  return degree * pi / 180;
}

// Function to convert radians to degrees
 function rad2deg(radian) {
  return radian * 180 / pi;
}

// Function to convert diopters to millimeters
function diopter2mm(diopter) {
  return 337.5 / diopter;
}

// Function to convert millimeters to diopters
function mm2diopter(mm) {
  return 337.5 / mm;
}

// Function to swap two double values
 function swapDoubles(double1, double2) {
  let temp1 = double1;
  let temp2 = double2;

  double1 = temp2;
  double2 = temp1;

  return { double1, double2 };
}


// Function to convert sphero-cylinder parameters
function convertSpheroCylinder({ sphere = 0, cylinder = 0, axis = 0, sign = '' }) {
  const sphereMinus = (cylinder <= 0) ? sphere : sphere + cylinder;
  const cylinderMinus = (cylinder <= 0) ? cylinder : -cylinder;
  const axisMinus = (cylinder <= 0) ? axis : axis + 90;
  
  const spherePlus = (cylinder >= 0) ? sphere : sphere + cylinder;
  const cylinderPlus = (cylinder >= 0) ? cylinder : -cylinder;
  const axisPlus = (cylinder >= 0) ? axis : axis + 90;

  sphere = (sign === '-') ? sphereMinus : sphere;
  cylinder = (sign === '-') ? cylinderMinus : cylinder;
  axis = (sign === '-') ? axisMinus : axis;
  
  sphere = (sign === '+') ? spherePlus : sphere;
  cylinder = (sign === '+') ? cylinderPlus : cylinder;
  axis = (sign === '+') ? axisPlus : axis;

  axis = checkAxis(axis);

  return { sphere, cylinder, axis };
}

module.exports={
  convertSpheroCylinder,
  deg2rad,
  rad2deg,
  diopter2mm,
  mm2diopter,
  swapDoubles
}