import React from "react";

const CardContainer = ({ children }) => {
  return (
    <div className="flex flex-column gap-5 border-solid border-round-lg p-4 bg-white ">
      {children}
    </div>
  );
};

export default CardContainer;
