import React from "react";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";

const StyledInput = ({ register, name, placeholder }) => {
  return (
    // <FloatLabel>
    //   <InputText
    //     id={name}
    //     {...register(name)}
    //     className="max-w-7rem border-2 border-900 py-3"
    //   />
    //   <label htmlFor={name} className="text-gray-900 font-medium">
    //     {placeholder}
    //   </label>
    // </FloatLabel>
    <div className="flex flex-column gap-1 align-items-center">
      <label htmlFor={name} className="text-gray-900 font-medium">
        {placeholder}
      </label>
      <InputText
        id={name}
        {...register(name)}
        className="max-w-7rem border-2 border-900 py-3"
      />
    </div>
  );
};

export default StyledInput;
